import ModalVideo from 'modal-video';
import 'modal-video/css/modal-video.css';

export default class {
  constructor(el) {
    this.dom = {el: el};

    this.options = {
      channel: 'custom',
      ratio: '16:9',
      // allowFullScreen: false
    }

    this.init();
  }

  init() {
    new ModalVideo(this.dom.el, this.options);
  }
}
