import Splide from '@splidejs/splide';
// import '@splidejs/splide/css';
import '@splidejs/splide/css/core';

export default class {
  constructor(el) {
    this.dom = {el: el};
    this.dom.slider = this.dom.el.querySelector('.splide');

    // HTMLCollectionから配列を作成
    // this.elements = [].slice.call(this.dom.tabs);

    this.slider = null;
    this.option = {
      autoWidth: true,
      speed: 600,
      arrows: false,
      arrowPath: false,
      pagination: false,
    };

    this.init();
  }

  init() {
    const _this = this;
    this.slider = new Splide(this.dom.slider, this.option);
    this.slider.mount();
  }

  getIndex(_target) {
    return this.elements.indexOf(_target);
  }

  slideChange(_index) {
    this.slider.go(_index);
  }
}
